<!-- 新闻 -->
<template>
	<div>
		<div class="news" v-if="djwork.length">
			<tap v-if="taps[0]" :taps="[taps[0].catname]" :isMore="false"></tap>
			<div class="cotain" v-if="djwork.length">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="(item,index) in djwork" :key="item.id" v-if="index<3"
							@click="clkTjyw(item.id)">
							<img :src="item.thumb" alt="">
						</div>
					</div>
					<div class="tle">
						<div>{{djwork[djIndex].title}}</div>
						<div class="circle">
							<div class="cle-item" v-for="(item,index) in djwork" :key="item.id" v-if="index<3"
								:class="djIndex==index?'active':''"></div>
						</div>
					</div>
				</div>
				<div class="right-ctx">
					<div class="ctx-item" v-for="(item,index) in djwork" :key="item.id" v-if="index<5"
						@click="clkTjyw(item.id)">
						<div class="ctx-tle">
							<span class="tle">{{item.title}}</span>
							<span class="time">{{item.inputtime}}</span>
						</div>
						<!-- <div class="cota">{{item.description}}</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="c-news">
			<div class="c-news-item" v-if="lzjs.length!=0">
				<tap v-if="taps[1]" :taps="[taps[1].catname]" @more="clkMoTjdt"></tap>
				<div style="overflow: hidden;">
					<img :src="lzjs[0].thumb" alt="" @click="clkTjAct(lzjs[0].id)">
				</div>
				<div class="title" @click="clkTjAct(lzjs[0].id)">{{lzjs[0].title}}</div>
				<div class="list-box">
					<div class="list-item" v-for="(item,index) in lzjs" v-if="index>0" @click="clkTjAct(item.id)">
						<div class="text">{{item.title}}</div>
						<div class="time">{{item.inputtime}}</div>
					</div>
				</div>
			</div>
			<div class="c-news-item c-news-item2" v-if="xxyd.length!=0">
				<tap v-if="taps[2]" :taps="[taps[2].catname]" @more="clkMoXxyd"></tap>
				<div style="overflow: hidden;">
					<img :src="xxyd[0].thumb" alt="" @click="clkTjAct(xxyd[0].id)">
				</div>
				<div class="title" @click="clkTjAct(xxyd[0].id)">{{xxyd[0].title}}</div>
				<div class="list-box">
					<div class="list-item" v-for="(item,index) in xxyd" v-if="index>0" @click="clkTjAct(item.id)">
						<div class="text">{{item.title}}</div>
						<div class="time">{{item.inputtime}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="activity" v-if="jdhd.length!=0">
			<tap v-if="taps[3]" :taps="[taps[3].catname]" :isMore="false"></tap>
			<ul class="list-box">
				<li class="list-item" v-for="(item,i) in jdhd" :key="i.id" @click="clkTjAct(item.id)">
					<div class="img">
						<img :src="item.thumb" alt="">
					</div>
					<div class="title">{{item.title}}</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import qs from "qs"
	export default {
		data() {
			return {
				djIndex: 0,
				// 党的工作
				djwork: [],
				//廉政建设专栏
				lzjs: [],
				//学习园地
				xxyd: [],
				//建党互动
				jdhd: [],
				taps: [],
				tapIndex: 0,
			}
		},
		created() {
			//党的建设
			this.LeftSidebar(12)
			//党的工作
			this.getddjs(13)
			//廉政建设专栏
			this.getddjs(14)
			//学习园地
			this.getddjs(15)
			//建党活动
			this.getddjs(16)
		},

		methods: {
			// 廉政建设专栏
			clkMoTjdt() {
				let url = this.$api.about.work_center
				let catid = 14
				let index = this.$route.query.index
				this.$router.push({name:"newMore",query:{index,url,catid,tap:'廉政建设专栏'}})
			},
			// 学习园地
			clkMoXxyd() {
				let url = this.$api.about.work_center
				let catid = 15
				let index = this.$route.query.index
				this.$router.push({name:"newMore",query:{index,url,catid,tap:'学习园地'}})
			},
			// -------点击更多

			// 点击党建要闻查看详情
			clkTjyw(id) {
				let index = this.$route.query.index
				this.$router.push({name:"newdetail",query:{index,id}})
			},
			// 点击党建活动
			clkTjAct(id) {
				let index = this.$route.query.index
				this.$router.push({name:"newdetail",query:{index,id}})
			},
			//左边导航栏
			LeftSidebar(nav_id) {
				let url = this.$api.about.LeftSidebar
				let data = {
					nav_id
				}
				this.$axios.post(url, qs.stringify(data)).then(res => {
					if (res.code) {
						
						this.taps = res.list
					}
				})

			},
			// 查找党的建设
			async getddjs(catid) {
				let url = this.$api.about.news_center
				let data = {
					catid,
					page: 1,
					limit: 6
				}
				let res = await this.$axios.post(url, qs.stringify(data))
				if (res.code) {
					if (catid == 13) {
						this.djwork = res.data.data
					} else if (catid == 14) {
						this.lzjs = res.data.data
					} else if (catid == 15) {
						this.xxyd = res.data.data
					} else if (catid == 16) {
						this.jdhd = res.data.data
					}
					this.$nextTick(()=>{
						this.getSwiper()
					})
				}
			},

			// 轮播图
			getSwiper() {
				let that = this
				new Swiper('.swiper-container', {
					pagination: '.swiper-pagination', //分页器
					loop: true, //循环
					paginationClickable: true, //分页器点击
					autoplay: 5000, //时长
					speed: 600,
					grabCursor: true,
					autoplayDisableOnInteraction: false,
					onSlideChangeEnd: function(swiper) {
						that.djIndex = swiper.realIndex
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.news {
		.cotain {
			display: flex;
			justify-content: space-between;

			.swiper-container {
				width: 5.24em;
				height: 3.51em;
				flex-shrink: 0;
				position: relative;

				.swiper-wrapper {
					.swiper-slide {
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.tle {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(0, 0, 0, .8);
					color: white;
					font-size: 12px;
					font-weight: 400;
					z-index: 999;
					padding: 15px 16px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					&>div:first-child {
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						width: 70%;
					}

					.circle {
						display: flex;

						.cle-item {
							width: 10px;
							height: 10px;
							border-radius: 50%;
							box-sizing: border-box;
							border: 1px solid white;
							margin-right: 5px;

							&:last-child {
								margin-right: 0;
							}
						}

						.active {
							background-color: var(--themeColor);
						}
					}
				}
			}

			.right-ctx {
				flex-grow: 1;
				font-size: 16px;
				display: grid;
				grid-template-rows: repeat(5, 1fr);
				margin-left: 30px;

				.ctx-item {
					display: flex;
					flex-direction: column;
					justify-content: center;
					border-bottom: 0.5px solid rgba(235, 236, 239, 1);
					cursor: pointer;

					&:hover {
						.ctx-tle {
							.tle {
								color: var(--themeColor);
								font-weight: bold;
							}

							.time {
								color: var(--themeColor);
								font-weight: bold;
							}
						}

						.cota {
							color: var(--themeColor);
							font-weight: bold;
						}
					}

					.ctx-tle {
						display: flex;
						align-items: baseline;
						justify-content: space-between;

						.tle {
							color: rgba(51, 51, 51, 1);
							font-size: 18px;
							font-weight: 500;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							padding-right: 60px;
						}

						.time {
							color: rgba(154, 160, 171, 1);
							font-size: 14px;
							font-weight: 400;
							flex-shrink: 0;
						}
					}

					.cota {
						color: rgba(93, 103, 121, 1);
						font-size: 14px;
						line-height: 21px;
						font-weight: 400;
						margin-top: 10px;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}
				}
			}
		}
	}

	.c-news {
		display: flex;
		justify-content: space-between;
		margin-top: 0.6em;

		.c-news-item {
			width: 5.24em;

			img {
				width: 100%;
				height: 2.4em;
				object-fit: cover;
				cursor: pointer;
				transition: all .5s;

				&:hover {
					transform: scale(1.1);
				}
			}

			.title {
				font-size: 18px;
				font-weight: 500;
				color: rgba(51, 51, 51, 1);
				line-height: 27px;
				padding: 20px 0;
				cursor: pointer;
				transition: all .2s;

				&:hover {
					color: var(--themeColor);
					text-decoration: underline;
				}
			}

			.list-box {
				.list-item {
					display: flex;
					justify-content: space-between;
					padding: 15px 0;
					border-top: 0.5px solid rgba(235, 236, 239, 1);
					cursor: pointer;

					&:hover {
						.text {
							color: var(--themeColor);
							text-decoration: underline;
						}

						.time {
							color: var(--themeColor);
						}
					}

					&:last-child {
						border-bottom: 0.5px solid rgba(235, 236, 239, 1);
					}

					.text {
						font-size: 14px;
						font-weight: 400;
						color: rgba(102, 102, 102, 1);
						width: 70%;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
					}

					.time {
						font-size: 14px;
						font-weight: 400;
						color: rgba(102, 102, 102, 1);
					}
				}
			}
		}
	}

	.activity {
		margin-top: 0.6em;

		.list-box {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			column-gap: 20px;
			row-gap: 30px;

			.list-item {
				transition: all .5s;
				cursor: pointer;

				&:hover {
					box-shadow: 0 0 10px rgba(0, 0, 0, .3);
					transform: translateY(-5px);

					img {
						transform: scale(1.1);
					}

					.title {
						color: var(--themeColor);
					}
				}

				.img {
					overflow: hidden;

					img {
						width: 100%;
						height: 2.06em;
						object-fit: cover;
						transition: all .5s;
					}
				}

				.title {
					font-size: 18px;
					font-weight: 500;
					padding: 20px;
					border: 0.5px solid rgba(235, 236, 239, 1);
					border-top: none;
				}
			}
		}
	}

	@media (max-width:1200px) {
		.news {
			.cotain {
				flex-direction: column;

				.swiper-container {
					width: 100%;
					height: 6em;
				}

				.right-ctx {
					margin-left: 0;
					display: block;

					.ctx-item {
						padding: 15px 0;
					}
				}
			}
		}
	}

	@media (max-width:768px) {
		.c-news {
			flex-direction: column;

			.c-news-item {
				width: 100%;
				.title {
					padding: 5px 0;
					font-size:14px;
					line-height: 20px;
				}
				img {
					height: 6em;
				}
			}

			.c-news-item2 {
				margin-top: 0.6em;
			}
		}

		.activity {
			.list-box {
				grid-template-columns: repeat(2, 1fr);

				.list-item {
					.img {
						img {
							height: 3em;
						}
					}

					.title {
						padding: 10px;
						font-size: 16px;
					}
				}
			}
		}
		
		
		.news {
			.cotain {
				.swiper-container {
					.tle {
						padding: 10px 10px;
					}
				}
				.right-ctx {
					.ctx-item {
						padding: 10px 0;
						.ctx-tle {
							.tle {
								font-size: 14px;
								padding-right: 20px;
							}
						}
					}
				}
			}
		}
	}
</style>
